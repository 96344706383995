export const formConstants = {
  login: [
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      required: true,
      placeholder: 'Email'
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      required: true,
      placeholder: 'Password'
    }
  ],
  diary: [
    {
      label: 'Testo',
      name: 'message',
      type: 'textarea',
      placeholder: 'Testo',
      required: true
    }
  ],
  thoughts: [
    {
      label: 'Autore',
      name: 'author',
      type: 'text',
      placeholder: 'Autore',
      required: true
    },
    {
      label: 'Pensiero',
      name: 'message',
      type: 'textarea',
      placeholder: 'Pensiero',
      required: true
    }
  ],
  guestbook: [
    {
      label: 'Autore',
      name: 'name',
      type: 'text',
      placeholder: 'Autore',
      required: true
    },
    {
      label: 'Messaggio',
      name: 'message',
      type: 'textarea',
      placeholder: 'Messaggio',
      required: true
    },
    {
      label: 'reCaptcha',
      name: 'recaptcha',
      type: 'recaptcha',
      placeholder: 'reCaptcha',
      required: true,
      noAdmin: true
    }
  ],
  wedding: [
    {
      label: 'Immagine',
      name: 'image',
      type: 'file',
      placeholder: 'Immagine',
      required: true
    }
  ],
  gallery: [
    {
      label: 'Immagine',
      name: 'image',
      type: 'file',
      placeholder: 'Immagine',
      required: true
    },
    {
      label: 'Titolo',
      name: 'title',
      type: 'text',
      placeholder: 'Titolo',
      required: true
    },
    {
      label: 'Descrizione',
      name: 'description',
      type: 'textarea',
      placeholder: 'Descrizione',
      required: true
    }
  ],
  events: [
    {
      label: 'Categoria',
      name: 'category',
      type: 'select',
      required: true,
      options: [
        { value: 'courses', label: 'Corsi' },
        { value: 'events', label: 'Eventi' }
      ]
    },
    {
      label: 'Data',
      name: 'date',
      type: 'date',
      placeholder: 'Data',
      required: true
    },
    {
      label: 'Titolo',
      name: 'title',
      type: 'textarea',
      placeholder: 'Titolo',
      required: true
    },
    {
      label: 'Descrizione',
      name: 'description',
      type: 'textarea',
      placeholder: 'Descrizione',
      required: true
    },
    {
      label: 'Immagine',
      name: 'image',
      type: 'file',
      placeholder: 'Immagine',
      required: true
    }
  ]
}
